import { Storager } from "./api_wrappers/storager";
import { show_global_loader } from "../components/testa/global_loader/global_loader";

export {}

interface EsbuildNotifyData {
    status: "success" | "in_progress" | "error",
    message: string
    extras: { [key: string]: any}
}

declare global {
    interface Window {
        enable_reloading: boolean
    }
}

window.enable_reloading = WEB_TYPE != "Logs"
// window.enable_reloading = true

const storager_key = "esbuild_success_message"
document.addEventListener("DOMContentLoaded", function() {
    const last_rebuilt_message = Storager.global.get<string>(storager_key)
    if (last_rebuilt_message != null) {
        toastr.success(last_rebuilt_message, null, { positionClass: "toast-bottom-right" })
        Storager.global.remove(storager_key)
    }

    let in_progress_notification: JQuery = null
    let rebuilt_items: string[] = []
    faye.subscribe("/assets/esbuild", (data: EsbuildNotifyData) => {
        console.log(data);
        if (data.status == "error") {
            toastr.error("ESBuild failed")
            console.error(data.extras);
        } else if (data.status == "in_progress") {
            if (in_progress_notification != null) in_progress_notification.remove()
            in_progress_notification = toastr.info("ESBuild in progress", null, { positionClass: "toast-bottom-right" })
            rebuilt_items = data.extras.paths_changed
        } else {
            if (window.enable_reloading) {
                console.log("Rebuilt following:", rebuilt_items);
                Storager.global.set(storager_key, data.message)
                show_global_loader()
                window.location.reload();
            }
        }
    })
})
